 <template>
  <!-- 样式组件 -->
  <div :class="'liefeng-forms-view'" v-if="!isParamComp">
    <img src="/images/forms.png" />
  </div>
  <!-- 参数设置组件 -->
  <div class="liefeng-forms-params" v-else>
    <Form :label-width="100" ref="listForm">
      <div class="liefeng-forms-tab">基础参数</div>
      <FormItem label="表单域背景">
        <ColorPicker
          v-model="data.background"
          recommend
          @on-active-change="
            (e) => {
              formsColorChange(e, 'background');
            }
          "
        />
      </FormItem>
      <FormItem label="文字颜色">
        <ColorPicker
          v-model="data.color"
          recommend
          @on-active-change="
            (e) => {
              formsColorChange(e, 'color');
            }
          "
        />
      </FormItem>
      <FormItem label="字体大小">
        <Input
          v-model.trim="data.fontSize"
          placeholder="默认：32px"
          type="text"
        ></Input>
      </FormItem>
      <FormItem label="是否阴影">
        <RadioGroup v-model="data.shadow">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="表单描述">
        <Input
          v-model.trim="data.formInfo"
          placeholder="顶部描述，选填"
          type="textarea"
          :autosize="true"
        ></Input>
      </FormItem>
      <div class="liefeng-forms-tab">表单参数</div>
      <FormItem label="预载数据接口">
        <Input
          v-model.trim="data.formSubmit.initDataApi"
          placeholder="预加载数据接口地址，对编辑等功能有用"
          type="textarea"
          :autosize="true"
        ></Input>
      </FormItem>
      <FormItem label="显示提交按钮">
        <RadioGroup v-model="data.formSubmit.show">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <block v-show="data.formSubmit.show">
        <FormItem label="按钮名称">
          <Input
            v-model.trim="data.formSubmit.text"
            placeholder="默认：提交"
            type="text"
          ></Input>
        </FormItem>
        <FormItem label="按钮文字颜色">
          <ColorPicker
            v-model="data.formSubmit.color"
            recommend
            @on-active-change="
              (e) => {
                formsSubmitColorChange(e, 'color');
              }
            "
          />
        </FormItem>
        <FormItem label="按钮背景颜色">
          <ColorPicker
            v-model="data.formSubmit.background"
            recommend
            @on-active-change="
              (e) => {
                formsSubmitColorChange(e, 'background');
              }
            "
          />
        </FormItem>

        <FormItem label="提交接口">
          <Input
            v-model.trim="data.formSubmit.action"
            placeholder="提交数据接口地址"
            type="textarea"
            :autosize="true"
          ></Input>
        </FormItem>
        <FormItem label="Content-Type">
          <RadioGroup v-model="data.formSubmit.contentType">
            <Radio :label="true">application/json</Radio>
            <Radio :label="false">x-www-form-urlencoded</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="成功返回上页">
          <RadioGroup v-model="data.formSubmit.goback">
            <Radio :label="true">是</Radio>
            <Radio :label="false">否</Radio>
          </RadioGroup>
        </FormItem>
      </block>
      <div class="liefeng-forms-tab">表单域参数</div>
      <FormItem label="JSON数据">
        <Input
          v-model.trim="data.formData"
          type="textarea"
          rows="20"
          placeholder='例：查看<<表单域JSON格式说明>>'
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('formData')"
          >格式化 / 验证JSON合法性</Button
        >
        <Button style="margin: 5px" type="info" @click="importDfData"
          >导入例子</Button
        >
      </FormItem>
      <FormItem>
        <Button style="margin: 5px" type="info" @click="showJSONHELP"
          >表单域JSON格式说明</Button
        > <APIreadme />
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import Forms from "./components/Forms";
 * 表单组件s
 */
import APIreadme from "./APIreadme";
export default {
  components: { APIreadme },
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "Forms",
        shadow: true, //默认阴影
        background: "#ffffff", //默认背景色
        color: "#333333", //默认字体颜色
        fontSize: "32px", //默认字体大小
        formInfo:"",//顶部描述
        formSubmit: {
          show: false, //是否显示
          text: "提交", //按钮名称
          color: "", //字体颜色
          background: "", //背景颜色
          initDataApi: "", //初始化数据，有则读取
          action: "", //提交接口地址
          contentType: true, //true为application/json，默认为application/x-www-form-urlencoded
          goback: true, //提交成功返回上一页
        },
        formData: null,
      },
      dfData: JSON.stringify(
        [
          {
            title: "文本框名称",
            type: "input",
            form: {
              type: "text",
              name: "inputname",
              placeholder: "文本框占位符",
              required: true,
            },
          },
          {
            title: "文本区名称",
            type: "textarea",
            form: {
              name: "textareaname",
              placeholder: "文本区占位符",
              maxLength: 300,
              height: 400,
              linkageNames: ["pickername", "inputname"],
              required: true,
            },
          },
          {
            background: "#ffffff",
            height: "20px",
            type: "line",
          },
          {
            title: "多选框名称",
            type: "checkbox",
            wrap: true,
            form: {
              name: "checkboxname",
              list: [
                { text: "多选一", value: "1" },
                { text: "多选二", value: "2" },
              ],
              api: "/oldpeople/api/common/sys/findDictByDictType?dictType=VOTE_DISCUSS",
              outputParams:{text:"dictValue",value:"dictKey"},
              linkageNames: null,
              required: true,
            },
          },
          {
            title: "单选框名称",
            type: "radio",
            wrap: true,
            form: {
              name: "radioname",
              list: [
                { text: "单选一", value: "1" },
                { text: "单选二", value: "2" },
                { text: "单选三", value: "3" },
                { text: "单选四", value: "4" },
              ],
              api: "/jsons/formdata.json",
              linkageNames: ["pickername", "inputname"],
              required: true,
            },
          },
          {
            title: "Picker名称",
            type: "picker",
            form: {
              mode: "selector",
              name: "pickername",
              list: [
                { text: "不选中", value: "" },
                { text: "单选一", value: "1" },
                { text: "单选二", value: "2" },
                { text: "单选三", value: "3" },
                { text: "单选四", value: "4" },
              ],
              api: "/jsons/formdata.json",
              outputParams:{},
              linkageNames: null,
              placeholder: "Picker占位符",
              required: true,
            },
          },
          {
            title: "Picker时间名称",
            type: "picker",
            form: {
              mode: "datetime",
              name: "pickertimename",
              linkageNames: null,
              placeholder: "Picker占位符",
              required: true,
            },
          },
          {
            title: "多列Picker名称",
            form: {
              mode: "multiSelector",
              name: "multpickername",
              list: [
                [
                  { text: "不选中", value: "" },
                  { text: "单选一", value: "1" },
                  { text: "单选二", value: "2" },
                  { text: "单选三", value: "3" },
                  { text: "单选四", value: "4" },
                ],
                [{ text: "不选中", value: "" }],
              ],
              api: "/jsons/formdata.json",
              outputParams:{},
              linkageNames: null,
              columnApi: "/jsons/formdata.json",
              placeholder: "Picker占位符",
              required: true,
            },
          },
          {
            title: "Rate评分",
            type: "rate",
            wrap: false,
            form: {
              name: "ratename",
              max: 5,
              required: true,
            },
          },
          {
            title: "slider",
            type: "slider",
            wrap: false,
            form: {
              name: "slidername",
              max: 5,
              min: 0,
              step: 1,
              required: true,
            },
          },
          {
            title: "switch",
            type: "switch",
            wrap: false,
            form: {
              name: "switchname",
              changeValue: {
                trueValue: "1",
                falseValue: "2",
              },
              required: true,
            },
          },
          {
            title: "图片选择器",
            type: "ImagePicker",
            wrap: false,
            form: {
              name: "ImagePickername",
              showAddBtn: true,
              count: 1,
              length: 4,
              required: true,
            },
          },
          {
            title: "富文本",
            type: "editor",
            form: {
              name: "editorname",
              required: true,
            },
            htmlName: "editorhtmlname",
          },
        ],
        null,
        "\t"
      ),
    };
  },
  mounted() {
    this.handleData();
  },
  methods: {
    importDfData() {
      if (this.data.formData) {
        this.$Modal.confirm({
          title: "提示",
          content: "编辑框已存在数据，是否覆盖？",
          onOk: (res) => {
            this.data.formData = this.dfData;
          },
        });
      } else {
        this.data.formData = this.dfData;
      }
    },
    handleData() {},
    formsColorChange(color, target) {
      this.data[target] = color;
    },
    formsSubmitColorChange(color, target) {
      this.data.formSubmit[target] = color;
    },
    verifyJson(target) {
      //验证JSON,每个组件需要具备
      try {
        if (!this.data[target]) {
          this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" });
        } else {
          var json = JSON.parse(this.data[target]);
          this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" });
          this.data[target] = JSON.stringify(json, null, "\t");
        }
      } catch (e) {
        this.$Modal.error({
          title: "验证结果",
          content: "JSON数据有误，请检查。",
        });
      }
    },
    showJSONHELP(){
      let str=`<div style="width:100%;height:500px;overflow:auto;"><pre>[
  {
    /*文本框实例*/
    title: "文本框名称", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "input", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    form: {
      //当前域标签的相关属性遵从form规则
      type: "text", //input：text、number...
      name: "inputname", //name,字段名
      placeholder: "文本框占位符", //占位符
      required: true, //是否必填
    },
  },
  {
    /*文本区实例*/
    title: "文本区名称", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "textarea", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    form: {
      //当前域标签的相关属性遵从form规则
      name: "textareaname", //name,字段名
      placeholder: "文本区占位符", //占位符
      maxLength: 300,
      height: 400,
      linkageNames: ["pickername", "inputname"], //参与联动的字段名集，相应字段自动触发本接口，并以相应字段name=value作为参数
      required: true, //是否必填
    },
  },
  {
    /*行间隔实例*/
    // background: "#555", //改变背景色
    height: "20px", //行间隔的高度
    type: "line", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
  },
  {
    /*多选框实例*/
    title: "多选框名称", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "checkbox", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    wrap: true, //单行换行显示
    form: {
      //当前域标签的相关属性遵从form规则
      name: "checkboxname", //name
      list: [
        { text: "多选一", value: "1" },
        { text: "多选二", value: "2" },
        { text: "多选三", value: "3" },
        { text: "多选四", value: "4" },
      ], //列表
      api: "http://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople/api/common/sys/findDictByDictType?dictType=VOTE_DISCUSS", //list数据列表接口
      outputParams:{},//接口数据指定输出的字段列表，如果是对象则多个字段会用,隔开输出，不填写则保留原数据: {"新参数名称": "原参数名称，若参数不存在，则视为常量"}
      linkageNames: null, //参与联动的字段名集，相应字段自动触发本接口，并以相应字段name=value作为参数
      required: true, //是否必填
    },
  },
  {
    /*单选框实例*/
    title: "单选框名称", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "radio", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    wrap: true, //单行换行显示
    form: {
      //当前域标签的相关属性遵从form规则
      name: "radioname", //name
      list: [
        { text: "单选一", value: "1" },
        { text: "单选二", value: "2" },
        { text: "单选三", value: "3" },
        { text: "单选四", value: "4" },
      ], //列表
      api: "/jsons/formdata.json", //list数据列表接口
      linkageNames: ["pickername", "inputname"], //参与联动的字段名集，相应字段自动触发本接口，并以相应字段name=value作为参数
      required: true, //是否必填
    },
  },
  {
    /*picker实例*/
    title: "Picker名称", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "picker", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    form: {
      //当前域标签的相关属性遵从form规则
      mode: "selector", //单行
      name: "pickername", //name
      list: [
        { text: "不选中", value: "" },
        { text: "单选一", value: "1" },
        { text: "单选二", value: "2" },
        { text: "单选三", value: "3" },
        { text: "单选四", value: "4" },
      ], //列表
      api: "/jsons/formdata.json", //list数据列表接口
      outputParams:{},//接口数据指定输出的字段列表，如果是对象则多个字段会用,隔开输出，不填写则保留原数据: {"新参数名称": "原参数名称，若参数不存在，则视为常量"}
      linkageNames: null, //参与联动的字段名集，相应字段自动触发本接口，并以相应字段name=value作为参数
      placeholder: "Picker占位符", //占位符
      required: true, //是否必填
    },
  },
  {
    /*picker date time实例*/
    title: "Picker时间名称", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "picker", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    form: {
      //当前域标签的相关属性遵从form规则
      mode: "datetime", //日期,datetime or date or time 参考picker
      name: "pickertimename", //name
      linkageNames: null, //参与联动的字段名集，相应字段自动触发本接口，并以相应字段name=value作为参数
      placeholder: "Picker占位符", //占位符
      required: true, //是否必填
    },
  },
  {
    /*多列picker实例*/
    title: "多列Picker名称", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "picker", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    form: {
      //当前域标签的相关属性遵从form规则
      mode: "multiSelector", //多行
      name: "multpickername", //name
      list: [
        [
          { text: "不选中", value: "" },
          { text: "单选一", value: "1" },
          { text: "单选二", value: "2" },
          { text: "单选三", value: "3" },
          { text: "单选四", value: "4" },
        ],
        [{ text: "不选中", value: "" }],
      ], //列表
      api: "/jsons/formdata.json", //list数据列表接口
      outputParams:{},//接口数据指定输出的字段列表，如果是对象则多个字段会用,隔开输出，不填写则保留原数据: {"新参数名称": "原参数名称，若参数不存在，则视为常量"}
      linkageNames: null, //参与联动的字段名集，相应字段自动触发本接口，并以相应字段name=value作为参数
      columnApi: "/jsons/formdata.json", //多行时，当API不为空，则读取API接口联动，参数为code
      placeholder: "Picker占位符", //占位符
      required: true, //是否必填
    },
  },
  {
    /*Rate评分实例*/
    title: "Rate评分", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "rate", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    wrap: false, //单行换行显示
    form: {
      //当前域标签的相关属性遵从form规则
      name: "ratename", //name,字段名
      max: 5, //星星个数
      required: true, //是否必填
    },
  },
  {
    /*slider实例*/
    title: "slider", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "slider", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    wrap: false, //单行换行显示
    form: {
      //当前域标签的相关属性遵从form规则
      name: "slidername", //name,字段名
      max: 5,
      min: 0,
      step: 1,
      required: true, //是否必填
    },
  },
  {
    /*switch实例*/
    title: "switch", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "switch", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    wrap: false, //单行换行显示
    form: {
      //当前域标签的相关属性遵从form规则
      name: "switchname", //name,字段名
      changeValue: {
        //数据转换,空则为原始值true or false
        trueValue: "1", //true转换为
        falseValue: "2", //false转换为
      },
      required: true, //是否必填
    },
  },
  {
    /*ImagePicker图片选择器实例*/
    title: "图片选择器", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "ImagePicker", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    wrap: false, //单行换行显示
    form: {
      //当前域标签的相关属性遵从form规则
      name: "ImagePickername", //name,字段名
      showAddBtn: true, //是否显示添加图片按钮
      count: 1, //最多可以选择的图片张数,>1为多选
      length: 4, //单行的图片数量，单选自动为2
      required: true, //是否必填
    },
  },
  {
    /*editor富文本实例*/
    title: "富文本", //属性名称
    // shadow: true, //带阴影
    // background: "#333", //改变背景色
    // color: "#fff", //改变字体颜色
    type: "editor", //当前域标签类型：input,select,checkbox,radio,line(行间隔)
    form: {
      //当前域标签的相关属性遵从form规则
      name: "editorname", //name,字段名
      required: true, //是否必填
    },
    htmlName: "editorhtmlname", //返回html的字段名，需要则填写
  },
];</pre></div>`;
      this.$Modal.info({
        title: "表单域JSON格式编写说明",
        content: str,
        width:"900px",
        scrollable: true,
      });
    }
  },
};
</script>
<style lang="less">
.liefeng-forms-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: #eee;
  img {
    width: 100%;
  }
}
.liefeng-forms-tab {
  width: 100%;
  background: #f5f5f5;
  margin: 20px 0;
  padding: 10px 20px;
  color: #555;
  font-weight: bold;
  overflow: hidden;
}
</style>